:root {
    /* Variables des couleurs */
    --bleu-fonce: #244a68;
    --bleu-clair: #d8eaee;
    --jaune: #ffce2f;
    --beige: #fbe6e4;
  
    /* Variables des polices */
    --oswald: 'Oswald', sans-serif;
    --roboto: 'Roboto', sans-serif;
}


.liensRS {
    display: flex;
    gap : 0.7em
}

.liensRS i {
    font-size: 26px;
    transition: transform 0.3s;
    position: relative; 
}

.liensRS i:hover {
    color: var(--jaune);
    cursor: pointer;
    transform: scale(1.1);
    display: inline-block;
}

.liensRS i::after {
    content: attr(data-label);
    color: var(--jaune);
    text-align: center;
    position: absolute;
    bottom: -30px; 
    left: 50%; 
    transform: translateX(-50%); 
    opacity: 0;
    transition: opacity 0.3s, bottom 0.3s, transform 0.3s; 
    white-space: nowrap;
    font-family: var(--roboto);
    font-size: 0.5em;
    font-weight: bold;
}

.liensRS i:hover::after {
    opacity: 1;
    bottom: -20px; 
}