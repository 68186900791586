@media (max-width: 60em) {
    .articleParagrapheEtImage {
        flex-direction: column;
        gap: 1em;
    }

    .articleParagrapheEtImage p, .articleParagrapheEtImage img {
        width: 100%;
        padding-left: 0em;
        padding-right: 0em;
    }

}