.mainFooter {
    text-align: center;
    text-transform: uppercase;
    background-color: var(--bleu-clair);
    color: var(--bleu-fonce);
    padding: 1em;
    margin-top: 4em;
    font-weight: bolder;
}

.mainFooter i{
    font-size: 1.3em;
    transition: transform 0.3s;
    margin-right: 0.3em;
}

.mainFooter i:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.share {
    display: flex;
    gap: 1em;
    color: var(--bleu-fonce);
    justify-content: center;
}