:root {
    /* Variables des couleurs */
    --violet-fonce: #551860;
    --violet-clair: #e4cae9;
    --bleu-fonce: #244a68;
    --bleu-clair: #d8eaee;
    --jaune: #ffce2f;
    --beige: #fbe6e4;
  
    /* Variables des polices */
    --oswald: 'Oswald', sans-serif;
    --roboto: 'Roboto', sans-serif;
  }

.labelForm {
    margin: 1.2em 0em 0.5em 0em;
    font-weight: 500;
    font-family: var(--roboto)
}

#message {
    margin-bottom: 2em;
}